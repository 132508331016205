<template>
    <header>
        <h1>Area riservata</h1>
    </header>
    <NavBar v-bind:pages="adminSitePages" />
    <slot></slot>
</template>

<script setup lang="ts">
import { adminSitePages } from "~/client/adminSitePages"

const unsubscribe = useRouter().beforeEach(async params => {
    if (!params.path.startsWith("/admin")) {
        await useFetch("/api/v1/auth/logout", {
            method: "POST"
        })
        unsubscribe()
    }
})
</script>

<style scoped>
header {
    padding: var(--m-large);
}

h1 {
    text-align: center;
}
</style>